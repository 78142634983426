import React, { useEffect, useRef } from 'react';
import "../OtpPages.css";
import { useNavigate } from 'react-router';
import logo from "../../../Assets/Images/FixIt_logo.png";



function FirstPageOtp() {

    const navigate = useNavigate()

    const clicked = () => {
        navigate("/second_otp")
    }



    const focus = useRef()

    useEffect(() => {



        focus.current.focus()

    }, [])





    return (



        <div class="question-survey change-first">
            <div className='head-question'>
                <span className='headque-span'>فرم اهراز هویت</span>
            </div>
            <div className='body-question'>

                <div className='firstotp-image'>
                    <img src={logo} className='image-mashinno' alt='fixit' />
                </div>

                <div className='input-firstotp'>
                    <span></span>
                </div>

                <div className='input-firstotp1'>
                    <label className='label-main'>شماره تلفن</label>
                    <input type='text' placeholder='' className='others-input' ref={focus} />
                </div>


            </div>
            <div className='btn-question'>
                <button className='btnque-button' onClick={clicked}>
                    ارسال درخواست
                </button>

            </div>

        </div>



    )
}

export default FirstPageOtp