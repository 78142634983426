import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { register } from 'swiper/element/bundle';
import slidpic1 from '../../Assets/iconImages/slide (1).jpg'
import slidpic2 from '../../Assets/iconImages/slide (1).png'
import slidpic3 from '../../Assets/iconImages/slide (2).jpg'
import slidpic4 from '../../Assets/iconImages/slide (3).jpg'


register();

function Slider() {

    useEffect(() => {

        const mainSwapSlider = document.querySelector("swiper-container");
        const swapSlider = document.querySelector("swiper-slide");
        swapSlider.addEventListener("click", () => {
            mainSwapSlider.swiper.slideNext();
        })


    }, [])

    return (

        // <div>
            <swiper-container slides-per-view="1" autoplay='true' speed="500" loop="true" css-mode="true" className='slider-container' style={{ width: "90%", height: 180, margin: "25px 5px" }} >
                <swiper-slide ><img src={slidpic4} className='slider-image' /></swiper-slide>
                <swiper-slide ><img src={slidpic3} className='slider-image' /></swiper-slide>
                <swiper-slide ><img src={slidpic2} className='slider-image' /></swiper-slide>
                <swiper-slide ><img src={slidpic1} className='slider-image' /></swiper-slide>
            </swiper-container>
        // </div>
    )
}

export default Slider