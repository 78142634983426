import React, { useContext, useEffect, useState } from 'react';
import { FaAccusoft } from "react-icons/fa";
import {
    BsHouseDoorFill, BsHouseDoor, BsInfoCircle,
    BsInfoCircleFill, BsTelephone, BsTelephoneFill
} from "react-icons/bs";
import { MyContext } from '../../Context/Contex';
import { Link, useNavigate } from 'react-router-dom';
import AboutUs from '../../AboutUs/AboutUs';



function Header() {


    const { changePage, setChangePage } = useContext(MyContext);
    const navigate = useNavigate();

    const homeClicked = () => {
        navigate('/home')
    }
    const aboutusClicked = () => {
        navigate('/partnership')
    }
    const contactClicked = () => {
        navigate('/contact')
    }


    return (

        <div className='header'>

            <div className={`header-div ${changePage === "home" ? "bor-buttom" : ""}`} onClick={homeClicked} >
                <div className='div-icon '>
                    {changePage === "home" ? <BsHouseDoorFill size={30} /> : <BsHouseDoor size={30} />}
                </div>
                <div className='div-text'>
                    خانه
                </div>
            </div>


            <div className={`header-div ${changePage === "partnership" ? "bor-buttom" : ""}`} onClick={aboutusClicked}>
                <div className='div-icon'>
                    {changePage === "partnership" ? <BsInfoCircleFill size={30} /> : <BsInfoCircle size={30} />}
                </div>
                <div className='div-text'>
                    همکاری با فیکسیت
                </div>
            </div>
            

        </div>
    )
}

export default Header