import React from 'react';
import "../Tools.css";
import Icon from '../Icon/Icon';
import { FaAccusoft } from "react-icons/fa";
import imagefooter1 from '../../Assets/iconImages/aa.png'
import imagefooter2 from '../../Assets/iconImages/aaa.png'
import imagefooter3 from '../../Assets/iconImages/bb.png'
import imagefooter4 from '../../Assets/iconImages/bbb.png'


function Footer() {
    return (

        <div className='footer'>
            <div className='under-foot1'>
                <div className='under-foot2'>
                    <Icon title={"لوازم یدکی"} bgColor={"#f00b20"} >
                        <img src={imagefooter1} className='image-icons' />
                    </Icon>
                </div>
            </div>
            <div className='under-foot1'>
                <div className='under-foot2'>
                    <Icon title={"خلافی"} bgColor={"#113ecb"} >
                        <img src={imagefooter2} className='image-icons' />
                    </Icon>
                </div>
            </div>
            <div className='under-foot1'>
                <div className='under-foot2'>
                    <Icon title={"مکانیک"} bgColor={"#0bb143"} >
                        <img src={imagefooter3} className='image-icons' />
                    </Icon>
                </div>
            </div>
            <div className='under-foot1'>
                <div className='under-foot2'>
                    <Icon title={"درباره ما"} bgColor={"#c80eeb"} >
                        <img src={imagefooter4} className='image-icons' />
                    </Icon>
                </div>
            </div>
        </div>
    )
}

export default Footer