import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './component/Home/Home';
import Footer from './component/Tools/Footer/Footer';
import MenuList from './component/Tools/MenuList/MenuList';
import Header from './component/Tools/Header/Header';
import { MyContext } from './component/Context/Contex';
import { useEffect, useState } from 'react';
import EazyPayPort from './component/Ports/EazyPayPort/EazyPayPort';
import TaliPort from './component/Ports/TaliPort/TaliPort';
import SaminPort from './component/Ports/SaminPort/SaminPort';
import AboutUs from './component/AboutUs/AboutUs';
import TaraPort from './component/Ports/TaraPort/TaraPort';
import FirstPageOtp from './component/AboutUs/Otp/FirstPage/FirstPage';
import SecondPageOtp from './component/AboutUs/Otp/SecondPage/SecondPage';



function App() {

  const [changePage, setChangePage] = useState('');
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/home')
  } , [])
  return (
    <MyContext.Provider value={{ changePage, setChangePage }}>
      <div className="App">
        <MenuList />
        <Header />
        <Footer />
        <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/partnership' element={<AboutUs />} />
          <Route path='/first_otp' element={<FirstPageOtp />} />
          <Route path='/second_otp' element={<SecondPageOtp />} />
          <Route path='/tali' element={<TaliPort />} />
          <Route path='/samin' element={<SaminPort />} />
          <Route path='/eazypay' element={<EazyPayPort />} />
          <Route path='/tara' element={<TaraPort />} />
        </Routes>
      </div>
    </MyContext.Provider>
  );
}

export default App;
