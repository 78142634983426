import React, { useContext, useEffect, useState } from 'react';
import "./AboutUs.css";
import { Link, useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/Contex';



function AboutUs(props) {

    const [name, setName] = useState("");
    const [coopration, setCoopration] = useState("");
    const [number, setNumber] = useState("");
    const [explaintion, setExplaintion] = useState("");

    const { changePage, setChangePage } = useContext(MyContext);

    const navigate = useNavigate();

    const clicked = () => {


        navigate("/first_otp")

        
    }


    useEffect(() => {
        const address = window.location.href.split('/');
        const linkAddress = address[address.length - 1]
        setChangePage(linkAddress);

    }, [])


    return (


        <div class="question-survey change-main">
            <div className='head-question'>
                <span className='headque-span'>فرم همکاری</span>
            </div>
            <div className='body-question'>

                <div className='input-main'>
                    <label className='label-main'>نام و نام خانوادگی</label>
                    <input type='text' placeholder='نام و نام خانوادگی' className='others-input' onChange={(e) => setName(e.target.value)} value={name} />
                </div>
                <div className='input-main'>
                    <label className='label-main'>شماره تماس</label>
                    <input type='text' placeholder='شماره تماس' className='others-input' onChange={(e) => setNumber(e.target.value)} value={number} />
                </div>
                <div className='input-main'>
                    <label className='label-main'>نوع همکاری</label>
                    <input type='text' placeholder='نوع همکاری' className='others-input' onChange={(e) => setCoopration(e.target.value)} value={coopration} />
                </div>
                <div className='textarea-main'>
                    <label className='label-main'>توضیحات</label>
                    <input type='textarea' placeholder='توضیحات' className='others-textarea' onChange={(e) => setExplaintion(e.target.value)} value={explaintion} />
                </div>



            </div>
            <div className='btn-question'>
                <button className='btnque-button' onClick={clicked}>
                    ارسال درخواست
                </button>
            </div>

        </div>


    )
}

export default AboutUs