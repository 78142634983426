import React from 'react'
import TemplatePorts from '../TemplatePort/TemplatePorts'

function TaraPort() {
  return (

    <TemplatePorts
    // tips={{}}
    HeaderPort={"درگاه تارا"}
    // tips1={{}}
    // tips2={{}}
    // tips3={{}}
    // tips4={{}}
    // MainText={{}}
    />


  )
}

export default TaraPort