import React from 'react';
import "./TemplatePort.css";
import { FaAngleLeft } from "react-icons/fa6";




function TemplatePorts(props) {


    return (


        <div class="question-survey">
            <div className='head-question'>
                <span className='headque-span'>{props.HeaderPort}</span>
            </div>
            <div className='body-question'>
                <div className='bodyque-div'>
                    <p>{props.MainText}</p>
                </div>
                <div className='bodyque-div1'>
                    {props.tips ?
                        <>
                            {props.tips1 != null ? <span><FaAngleLeft className='faangle-left' size={15} /> {props.tips1}</span> : null}
                            {props.tips2 != null ? <span><FaAngleLeft className='faangle-left' size={15} /> {props.tips2}</span> : null}
                            {props.tips3 != null ? <span><FaAngleLeft className='faangle-left' size={15} /> {props.tips3}</span> : null}
                            {props.tips4 != null ? <span><FaAngleLeft className='faangle-left' size={15} /> {props.tips4}</span> : null}
                        </> : null}
                </div>
            </div>

            {/* <div className='btn-question'>
                <Link to={props.Link} >
                    <button className='btnque-button' onClick={props.clicked}>
                        {props.btnText}
                    </button>
                </Link>
            </div> */}

        </div>


    )
}

export default TemplatePorts;