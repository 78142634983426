import React from 'react'
import TemplatePorts from '../TemplatePort/TemplatePorts'

function EazyPayPort() {
  return (

    <TemplatePorts
      tips={true}
      HeaderPort={"درگاه ایزی پی"}
      // tips1={"نکته شماره یک را وارد کردیم"}
      // tips2={"نکته شماره یک را وارد کردیم"}
      // tips3={"نکته شماره یک را وارد کردیم"}
      // tips4={"نکته شماره یک را وارد کردیم"}
      MainText={"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،"}
    />
  )
}

export default EazyPayPort ;