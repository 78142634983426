import React from 'react';
import { FaAccusoft } from "react-icons/fa";
import { IoIosNotificationsOutline  } from "react-icons/io";
import { BsPerson , BsWallet2  } from "react-icons/bs";
import logo from '../../Assets/Images/FixIt_logo.png'

function MenuList() {

    return (

        <div className='menu-item'>
            <div className='img-list'>
                <img src={logo} alt='' className='menuitem-img' />
            </div>
            <div className='icon-list'>
                <div className='icons'>
                    <BsWallet2 size={20} />
                </div>
                <div className='icons'>
                    <BsPerson  size={25}/>
                </div>
                <div className='icons'>
                    <IoIosNotificationsOutline size={25}/>
                </div>
            </div>
        </div>
    )
}

export default MenuList