import React, { useContext, useEffect } from 'react'
import "./Home.css";
import Banner from '../Tools/Banner/Banner';
import Icon from '../Tools/Icon/Icon';
import { FaAccusoft } from "react-icons/fa";
import Slider from '../Tools/Slider/Slider';
import { MyContext } from '../Context/Contex';
import BlogSlider from '../Tools/Slider/BlogSlider';
import { FaAnglesLeft, FaArtstation } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import image1 from '../Assets/iconImages/bime.png'
import image2 from '../Assets/iconImages/3 (2).png'
import image3 from '../Assets/iconImages/barrier.png'
import image4 from '../Assets/iconImages/67 (2).png'
import image5 from '../Assets/iconImages/accident-car.png'
import image6 from '../Assets/iconImages/car-crash.png'


import imagePort1 from '../Assets/iconImages/car-insurance (4).png'
import imagePort2 from '../Assets/iconImages/car-insurance (6).png'
import imagePort3 from '../Assets/iconImages/car-insurance (9).png'
import imagePort4 from '../Assets/iconImages/insurance (5).png'




function Home() {


    const { changePage, setChangePage } = useContext(MyContext);

    useEffect(() => {
        const address = window.location.href.split('/');
        const linkAddress = address[address.length - 1]
        setChangePage(linkAddress);

    }, [])





    return (
        <div className='Home'>


            <div className='lis-teek'>
                <div className='icon-field'>
                    <Icon title={"بیمه"} bgColor={"#AF7AC5"} >
                        <img src={image1} className='image-icons' />
                    </Icon>
                </div>
                <div className='icon-field'>
                    <Icon title={"استعلام گواهینامه"} bgColor={"#E74C3C"} >
                        <img src={image2} className='image-icons' />
                    </Icon>
                </div>
                <div className='icon-field'>
                    <Icon title={"استعلام نمره منفی"} bgColor={"#20cb63"} >
                        <img src={image3} className='image-icons' />
                    </Icon>
                </div>
            </div>
            <div className='lis-teek'>
                <div className='icon-field'>
                    <Icon title={"تست"} bgColor={"#1eafcc"} >
                        <img src={image4} className='image-icons' />
                    </Icon>
                </div>
                <div className='icon-field'>
                    <Icon title={"تست"} bgColor={"#F7DC6F"} >
                        <img src={image5} className='image-icons' />
                    </Icon>
                </div>
                <div className='icon-field'>
                    <Icon title={"تست"} bgColor={"#2471A3"} >
                        <img src={image6} className='image-icons' />
                    </Icon>
                </div>
            </div>

            <Slider />




            <h3>درگاه های طرف قرارداد</h3>
            <div className='lis-teek'>
                <div className='icon-field'>
                    <Icon title={"تالی"} link={"/tali"}>
                        <img src={imagePort4} className='image-icons' />
                    </Icon>

                </div>
                <div className='icon-field'>
                    <Icon title={"ایزی پی"}  link={"/eazypay"}>
                        <img src={imagePort1} className='image-icons' />
                    </Icon>

                </div>
                <div className='icon-field'>
                    <Icon title={"تارا"} link={"/tara"}>
                        <img src={imagePort2} className='image-icons' />
                    </Icon>

                </div>
                <div className='icon-field'>
                    <Icon title={"سمین"} link={"/samin"} >
                        <img src={imagePort3} className='image-icons' />
                    </Icon>
                </div>
            </div>


            {/* <Banner add={"assets/app/photo_2023-12-23_16-38-51.jpg"} title={"انواع لوازم جانبی مورد نیاز شما"} /> */}
            {/* <Banner add={"assets/app/photo_2023-12-23_16-38-51.jpg"} title={"انواع لوازم نگه داری خودرو"} /> */}
            {/* <Banner add={"assets/app/photo_2023-12-23_16-38-51.jpg"} title={"انواع لوازن نگهداری از خودرو"} /> */}

            <div className='titr-blog'>
                <div className='right-item'>
                    <div className='right-icon'>
                        <FaArtstation size={20} />
                    </div>
                    <div className='right-text'>
                        مجله فیکسیت
                    </div>
                </div>
                <div className='left-item'>
                    <div className='left-text'>
                        مشاهده همه
                    </div>
                    <div className='left-icon'>
                        <FaAnglesLeft size={20} />
                    </div>
                </div>
            </div>




            <BlogSlider />

        </div>
    )
}

export default Home