import React from 'react';
import "../Tools.css";
import { Link } from 'react-router-dom';


function Icon(props) {

    return (
        <Link to={props.link} >
            <div className='bodyicon'>
                <div className='dis-mid' style={{ height: 50, width: 50, borderRadius: "50%", backgroundColor: `${props.bgColor}` }} >
                    {props.children}
                </div>
                <div className='text-class'>
                    <text>{props.title}</text>
                </div>

            </div>
        </Link>
    )
}

export default Icon;
