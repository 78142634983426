import React from 'react'
import TemplatePorts from '../TemplatePort/TemplatePorts'

function SaminPort() {
  return (

    <TemplatePorts
    // tips={{}}
    HeaderPort={"درگاه ثمین"}
    // tips1={{}}
    // tips2={{}}
    // tips3={{}}
    // tips4={{}}
    // MainText={{}}
    />

  )
}

export default SaminPort